import gitIcon from "../assets/gitIcon.png";
function About() {
  return (
    <div className="About container d-flex flex-column justify-content-center align-items-center text-center">
      <h1>Griyaka cv oluşturucu</h1>
      <h2>Ücretsiz ve hızlı cv oluştur</h2>
      <h2>www.griyaka.com</h2>
    </div>
  );
}
export default About;
