import { useState } from "react";
function ProfessionalSummary(props) {
  const { summary, setSummary } = props;
  return (
    <section>
      <h3  className="d-flex flex-column flex-md-row justify-content-center" style={{marginBottom:30,marginTop:30}}>Hakkımda/Ön yazı</h3>
      <div className="w-100 text-area-div d-flex justify-content-center ">
        <textarea
          className="form-control"
          id="summary"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
        ></textarea>
      </div>
    </section>
  );
}

export default ProfessionalSummary;
