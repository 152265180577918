import { useState } from "react";

function PersonalDetails(props) {
  const { personalDetails, setPersonalDetails } = props;

  const handleChange = (e) => {
    const name = e.target.name;
    if (name == "photo") {
      setPersonalDetails({ ...personalDetails, photo: e.target.files[0] });
    } else {
      setPersonalDetails({ ...personalDetails, [name]: e.target.value });
    }
  };

  return (
    <section id="pd">
      <h3 className="d-flex flex-column flex-md-row justify-content-center" >Kişisel bilgiler</h3>
      <div className="d-flex flex-column flex-md-row justify-content-center">
      <div className="mb-3 mx-2 w-100 input-div ">
        Fotoğraf yükle

        {personalDetails.photo != 0 ? [
             <label  style={{paddingLeft:25,padding:5,marginTop:10,width:"100%",backgroundColor:"white",height:35,borderRadius:4,color:"#62BD69",fontWeight:900}} for="photo" className="form-label">
              Fotoğrafınız başarıyla yüklendi
               </label>
     
        ]:[ <label  style={{paddingLeft:25,padding:5,marginTop:10,width:"100%",backgroundColor:"white",height:35,borderRadius:4,color:"#909090",fontWeight:900,}} for="photo" className="form-label">
              Fotoğrafınızı yüklemek için tıklayın...
          </label>]}

     

          <input
            className="form-control"
            accept="image/*"
            name="photo"
            value={personalDetails.Photo}
            type="file"
            id="photo"
            style={{display: 'none'}}
            onChange={handleChange}
          />
         
        </div>
        <div className="mb-3 mx-2 w-100 input-div">
          
          <label for="job-title" className="form-label">
            Meslek/Sektör
          </label>
          <input
            type="text"
            name="jobTitle"
            value={personalDetails.jobTitle}
            className="form-control"
            id="job-title"
            onChange={handleChange}
          />
        </div>
        
    
      </div>
      
      <div className="d-flex flex-column flex-md-row justify-content-center">
        <div className="mb-3 mx-2 w-100 input-div" style={{marginTop:0}}>
          <label for="first-name" className="form-label" >
            Ad
          </label>
          <input
            type="text"
            name="firstName"
            value={personalDetails.firstName}
            className="form-control"
            id="first-name"
            onChange={handleChange}
          />
        </div>
      
        <div className="mb-3 mx-2 w-100 input-div"  style={{marginTop:0}}>
          <label for="last-name" className="form-label">
            Soyad
          </label>
          <input
            type="text"
            className="form-control"
            name="lastName"
            value={personalDetails.lastName}
            id="last-name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-center">
        <div className="mb-3 mx-2 w-100 input-div">
          <label for="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={personalDetails.email}
            name="email"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 mx-2 w-100 input-div">
          <label for="phone-number" className="form-label">
            Telefon numarası
          </label>
          <input
            type="tel"
            value={personalDetails.phoneNumber}
            name="phoneNumber"
            className="form-control"
            id="phone-number"
            onChange={handleChange}
          />
        </div>
      </div>

      
      <div className="d-flex flex-column flex-md-row justify-content-center">
           <div className="mb-3 mx-2 w-100 input-div">
          <label for="gender" className="form-label" >
            Cinsiyet
          </label>
          <input
            type="text"
            name="gender"
            value={personalDetails.gender}
            className="form-control"
            id="gender"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 mx-2 w-100 input-div">
          <label for="address" className="form-label">
            Adres
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            value={personalDetails.address}
            name="address"
            onChange={handleChange}
          />
        </div>

      </div>
    </section>
  );
}

export default PersonalDetails;
