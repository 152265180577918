function _404() {
  return (
    <div className="About d-flex flex-column justify-content-center align-items-center text-center">
      <h1>Üzgünüz bir hata oluştur.</h1>
      <h1>404 :(</h1>
    </div>
  );
}

export default _404;
